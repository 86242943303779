.panel-live-monitoring {
  > .title {
    margin-bottom: 15px;

    > span {
      font-size: 33px;
      margin-right: 25px;
    }

    > select {
      max-width: 350px;
      font-size: 17px;
      height: 37px;
      display: inline-block;
    }

    > .Select {
      width: 350px;
      display: inline-block;
      height: 27px;
    }

    > .loader {
      margin-left: 10px;
      display: inline-block;
    }
  }

	.panel-live-monitoring-container {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		column-gap: 15px;
	}
}

#pallet-jack-live-monitoring-container {
	.pallet-jack-box-simplified {
		button {
			font-weight: bold;
			font-size: 18px;
			width: 80px;
	
			.fa {
				color: rgba(0, 0, 0, 0.4);
			}
		}
	}
	
	.pallet-jack-box {
		box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		width: 250px;
		height: 105px;
		text-align: left;
		
		&:hover {
			filter: brightness(85%);
			cursor: pointer;
		}
	
		.charging-progress-bar {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border-radius: 5px;
			opacity: .8;
		}
	
		.box-content {
			position: relative;
			min-height: 80px;
			padding: 0 10px;
	
			.battery-info {
				font-weight: bold;
				font-size: 15px;
			}
	
			.pallet-jack-box-title {
				.title {
					font-weight: bold;
					font-size: 55px;
				}
	
				.box-icon {
					color: rgba(0, 0, 0, 0.3);
					font-size: 65px;
					position: absolute;
					right: 15px;
					top: -5px;
	
					.fa-battery-0, .fa-battery-1, .fa-battery-2, .fa-battery-3, .fa-battery-4 {
						margin-top: 15px;
					}
	
					.fa-plug {
						font-size: 60px;
					}
	
					.battery-percentage {
						width: 100%;
						color: white;
						position: absolute;
						right: 2px;
						top: 31px;
						text-align: center;
						font-size: 22px;
						font-weight: bold;
					}
				}
			}
	
			.table {
				th {
					width: 105px;
					vertical-align: bottom;
				}
	
				td {
					font-size: 16px;
					@media screen and (max-width: 400px) {
						font-size: 12px;
					}
				}
	
				th,
				td {
					border-top: none;
					border-bottom: 1px solid #f4f4f4;
					padding: 4px 0px;
					text-align: left;
				}
			}
		}
	}
}