.panel-live-monitoring > .title {
  margin-bottom: 15px;
}
.panel-live-monitoring > .title > span {
  font-size: 33px;
  margin-right: 25px;
}
.panel-live-monitoring > .title > select {
  max-width: 350px;
  font-size: 17px;
  height: 37px;
  display: inline-block;
}
.panel-live-monitoring > .title > .Select {
  width: 350px;
  display: inline-block;
  height: 27px;
}
.panel-live-monitoring > .title > .loader {
  margin-left: 10px;
  display: inline-block;
}
.panel-live-monitoring .panel-live-monitoring-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}

#pallet-jack-live-monitoring-container .pallet-jack-box-simplified button {
  font-weight: bold;
  font-size: 18px;
  width: 80px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box-simplified button .fa {
  color: rgba(0, 0, 0, 0.4);
}
#pallet-jack-live-monitoring-container .pallet-jack-box {
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 250px;
  height: 105px;
  text-align: left;
}
#pallet-jack-live-monitoring-container .pallet-jack-box:hover {
  filter: brightness(85%);
  cursor: pointer;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .charging-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  opacity: 0.8;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content {
  position: relative;
  min-height: 80px;
  padding: 0 10px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .battery-info {
  font-weight: bold;
  font-size: 15px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .title {
  font-weight: bold;
  font-size: 55px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon {
  color: rgba(0, 0, 0, 0.3);
  font-size: 65px;
  position: absolute;
  right: 15px;
  top: -5px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .fa-battery-0, #pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .fa-battery-1, #pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .fa-battery-2, #pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .fa-battery-3, #pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .fa-battery-4 {
  margin-top: 15px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .fa-plug {
  font-size: 60px;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .pallet-jack-box-title .box-icon .battery-percentage {
  width: 100%;
  color: white;
  position: absolute;
  right: 2px;
  top: 31px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .table th {
  width: 105px;
  vertical-align: bottom;
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .table td {
  font-size: 16px;
}
@media screen and (max-width: 400px) {
  #pallet-jack-live-monitoring-container .pallet-jack-box .box-content .table td {
    font-size: 12px;
  }
}
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .table th,
#pallet-jack-live-monitoring-container .pallet-jack-box .box-content .table td {
  border-top: none;
  border-bottom: 1px solid #f4f4f4;
  padding: 4px 0px;
  text-align: left;
}

