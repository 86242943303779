.map-control {
	margin: 10px;
	width: 90%;
	max-width: 300px;
	box-shadow: 4px 3px 3px rgba(0, 0, 0, .5);
}

.google-marker-label {
	margin-top: 10px;
	background-color: #585858;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, .6);
	border-radius: 10px;
	color: white !important;
	font-size: 12px !important;
	padding: 5px;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
}

.google-maps-marker-label-new {
	background-color: white;
	padding: 1px 5px;
	font-size: 14px !important;
	font-weight: bold !important;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, .4);
	border-radius: 5px;

	&.default-marker {
		margin-top: 80px;
	}
}