$text-red: #db2828;
$text-green: #008000;
$text-blue: #2185d0;

$bg-red: rgba(219, 40, 40, 0.07);
$bg-blue: rgba(33, 133, 208, 0.07);
$bg-green: rgba(0, 128, 0, 0.07);

body {
	font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 14px;
  line-height: 1.42857143;
}

body,
#root>.wrapper {
	height: auto;
}

body {
	background-color: #ecf0f5;
}

a {
	cursor: pointer;
}

.text-italic {
	font-style: italic;
}

h3.title {
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
	color: #666;
	font-weight: 600;
	margin-bottom: 15px;
	padding-bottom: 3px;
}

h5.title-obs {
	color: #636363;
	font-style: italic;
	font-size: 1.7rem;
	margin-bottom: 20px;
}

/* Absolute Center Spinner */
.full-overlay {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.55);
	z-index: 1030;
}

.text-lg {
	font-size: 6em;
}

.cursor-pointer {
	cursor: pointer;

	&:hover {
		filter: brightness(110%);
	}
}

.cursor-search {
	cursor: zoom-in;
}

.screen-height {
	height: 70vh;
}

.Select {
	z-index: 3;

	.Select-control {
		border-radius: 0 !important;
	}

	.Select-input,
	.Select-control {
		height: 32px !important;
	}

	&.is-open {
		z-index: 100 !important;
	}
}

.vehicle-details table tr {
	td {
		width: 50%;
	}

	>td {
		max-width: 200px;
	}
}

.text-right {
	text-align: right;
}

.text-middle {
	vertical-align: middle !important;
}

table {
	.text-right {
		text-align: right;
	}

	&.text-middle {
		th,
		td {
			vertical-align: middle !important;
		}
	}

	td {
		&.cursor-pointer:hover {
			color: rgb(60, 141, 188);
			text-decoration: underline;
		}
	}

	tr.deleted {
		td, th {
			&:not(.ignore-deleted) {
				text-decoration: line-through;
			}
		}
	}

	th, td {
		&.table-action-button {
			cursor: pointer;
			font-size: 20px;
			padding: 3px !important;
			text-align: center;
			vertical-align: middle !important;


			&.red {
				color: #bf493a;
			}

			&.green {
				color: #2d9063;
			}

			&.orange {
				color: #f39c12;
			}

			&.blue {
				color: #13aed4;
			}

			&:hover {
				filter: brightness(80%);
			}
		}
	}
}

table.no-border td {
	border: none;
}

table.no-margin-bottom {
	margin-bottom: 0
}

.app-version {
	bottom: 5px;
	color: #888888;
	left: 10px;
	position: absolute;
	z-index: 810;
}

.main-footer {
	height: 35px !important;
	padding: 8px 10px !important;
	font-size: 12px !important;
}

@media only screen and (max-width : 768px) {}

@media print {
	canvas {
		width: 100% !important;
	}

	img {
		max-width: none !important;
		max-height: none !important;
	}

	.google-map {
		width: 100vh;
	}

	.graph-box {
		width: 100%;
		page-break-inside: avoid !important;
	}

	[class*="col-lg"],
	[class*="col-md"],
	[class*="col-sm"] {
		width: 100%;
	}

	.toast,
	.btn,
	.dataTables_length,
	.dataTables_filter,
	.dataTables_paginate,
	.dataTables_info {
		display: none !important;
	}

	.hidden-xs {
		display: block !important;
	}
}

.profile-user-img {
	height: 100px;
}

.action-btn {
	padding: 6px;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;

	&.action-view-btn {
		color: #3c8dbc;
		font-size: 22px;

		&:hover {
			color: #397292;
		}
	}
}

.remove-btn {
	color: #da1010;
	cursor: pointer;

	&:hover {
		color: #b31616;
	}

	&:active {
		color: #961717;
	}
}

.edit-btn {
	color: #ce8a0c;
	cursor: pointer;

	&:hover {
		color: #a77318;
	}

	&:active {
		color: #8e6215;
	}
}

.btn-rounded {
	border-radius: 50% !important;
}

p {
	white-space: pre-wrap;
}

.content-wrapper,
.main-footer {
	&.full-width {
		margin-left: 0 !important;
	}
}

.sidebar-mini.sidebar-collapse {

	.content-wrapper,
	.main-footer {
		&.full-width {
			margin-left: 0 !important;
		}
	}
}

.blink:not(:hover) {
	animation: blinker 2s linear infinite;
}

@keyframes blinker {
  0% { opacity: 1; } /* Start at full opacity */
  30% { opacity: 0.4; } /* Lower opacity for a shorter period */
  60% { opacity: 1; } /* Back to full opacity */
}

.loading-page {
	color: #0d4a6d;
	text-align: center;
	background-color: #f0f3f7;
	height: 100vh;
	overflow: hidden;
	padding-top: 20vh;

	.loader {
		width: 80px;
		height: 80px;
		border-width: 7px;
	}

	.logo {
		display: inline;
		max-height: 130px;
	}

	i {
		font-size: 23px;
		display: block;
	}
}

.error-page {
	h1 {
		font-size: 50px;
		margin-top: 5px;
	}

	h3 {
		font-size: 20px;
		font-weight: bold;
	}

	.message-content {
		font-size: 17px;
	}

	.logo {
		display: inline;
		max-height: 130px;
	}
}

@media only screen and (max-width : 768px) {
	.error-page {
		h1 {
			font-size: 40px;
			margin-top: 5px;
		}

		img {
			max-height: 120px;
		}

		.message-content {
			font-size: 15px;
			padding: 10px;
		}
	}
}

.text-red {
	color: $text-red;
}

.text-blue {
  color: $text-blue;
}

.text-green {
  color: $text-green;
}

.text-orange {
	color: #b64900 !important;
}

.background-red {
  background: $bg-red !important;
}

.background-blue {
  background: $bg-blue !important;
}

.background-green {
  background: $bg-green !important;
}

// .background-red,
// .background-blue,
// .background-green {
// 	&:hover {
// 		filter: brightness(0.4) !important;
// 	}
// }

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-blue,
.text-green,
.text-red {
  .cursor-pointer:hover {
    filter: brightness(.7);
  }
}

select > option:disabled {
	color: #b3b3b3;
}

.Select-value {
	font-weight: normal;
}

.Select-option {
	&.text-danger {
		color: red;
	}

	&.text-warning {
		color: orange;
	}

	&.text-success {
		color: green;
	}
}

.sortable-list {
	.sortable-chosen {
		.input-group {
			filter: brightness(.85)
		}
	}
}

.sortable-list-index {
	padding: 21px 20px;
	background-color: #13aed4;
	border-radius: 5px;
	text-align: center;
	color: white;
	font-weight: bold;
}


.home-link-container {
	.badge {
		position: absolute;
		right: 20px;
		top: 5px;
		background: #e3e61c;
		color: #000;
	}
}

.flex {
	display: flex;
	
	&.flex-row {
		flex-direction: row;
	}

	&.flex-column {
		flex-direction: column;
	}
}

.flex-1 {
	flex: 1
}

.flex-2 {
	flex: 2
}

.flex-3 {
	flex: 3
}

.flex-4 {
	flex: 4
}

.flex-5 {
	flex: 5
}

.react-tooltip {
	max-width: 300px;
	text-align: center;
	font-size: 15px !important;
	font-weight: normal !important;
	opacity: 1 !important;
}